import React, { Fragment, useState, useEffect, KeyboardEventHandler } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { MenuIcon, XIcon, ArrowRightIcon, LightBulbIcon, ExclamationIcon, InformationCircleIcon, QuestionMarkCircleIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline'
import { HomeIcon, ScaleIcon, ExclamationCircleIcon, MailIcon } from '@heroicons/react/solid'
import Image from 'next/image'
import Link from 'next/link'
import parse from "html-react-parser";
import AccordionItem from '../accordion/AccordionItem'
import StepForm from '../form/LawsuitForm/StepForm'
import { supabase } from '../../utils/supabaseClient';
import StepFormV2 from '../form/LawsuitForm/StepFormV2';
import StepModal from '../form/LawsuitForm/StepModal';
import { useRouter } from 'next/router'
import BackButtonModal from '../modal/BackButtonModal'
import ScrollModal from '../modal/ScrollModal'

export default function LawsuitPost({ did, source, lawsuit }) {

    const router = useRouter()
    const [cited, showCited] = useState(false)
    const [open, setOpen] = useState(false)
    const [back, setBack] = useState(false)
    const [scroll, setScroll] = useState(false)
    const [scrollHide, setScrollHide] = useState(false)
    const [backOnce, setBackOnce] = useState(false)
    const [success, setSuccess] = useState(false)

    const accordionArray = [
        {
            id: 1,
            question: lawsuit.question_1,
            answer: lawsuit.answer_1
        },
        {
            id: 2,
            question: lawsuit.question_2,
            answer: lawsuit.answer_2
        },
        {
            id: 3,
            question: lawsuit.question_3,
            answer: lawsuit.answer_3
        },
        {
            id: 4,
            question: lawsuit.question_4,
            answer: lawsuit.answer_4
        },
    ]

    const [isHovering, setIsHovering] = useState(false);

    //for the tool tip
    const handleMouseOver = () => {
        setIsHovering(true);
    };
    //for the tool tip
    const handleMouseOut = () => {
        setIsHovering(false);
    };

    // //for the back button
    // useEffect(() => {
    //     var mouseY = 0;
    //     var topValue = 0;
    //     if (backOnce) {
    //         setBack(false)
    //         const timer = setTimeout(() => {
    //             setBackOnce(false)
    //         }, 60000); 
    //         return () => clearTimeout(timer);
    //     } else {
    //         window.addEventListener("mouseout", function (e) {
    //             mouseY = e.clientY;
    //             if (mouseY < topValue) {
    //                 setBack(true)
    //                 setScroll(false)
    //             }
    //         },
    //             false);
    //     }
    // })

    // //timer
    // useEffect(() => {
    //     const timer = setTimeout(() => {
    //         setScroll(true)
    //         setBack(false)
    //     }, 30000); 
    //     return () => clearTimeout(timer);
    // }, []);


    return (
        <div className='bg-gradient-to-b from-white to-zinc-50'>
            {
                back && !success &&
                <div>
                    <BackButtonModal
                        lawsuit={lawsuit.title}
                        did={did}
                        backOnce={backOnce}
                        setBackOnce={setBackOnce}
                        open={back}
                        setOpen={setBack}
                        openForm={open}
                        setOpenForm={setOpen}
                    />
                </div>
            }
            {
                scroll && !scrollHide && !success &&
                <div>
                    <ScrollModal
                        lawsuit={lawsuit.title}
                        did={did}
                        open={scroll}
                        setOpen={setScroll}
                        openForm={open}
                        setOpenForm={setOpen}
                    />
                </div>
            }
            {/* <div className="mx-auto">
                {lawsuit.active_lawsuit &&
                    <div className="sticky top-0 z-10 bg-red-100">
                        <main className="mx-auto max-w-7xl px-10">
                            <div className="text-center">
                                
                                <div className="flex md:flex-row flex-col items-center justify-center bg-red-100 ml-3 p-2 rounded md:rounded-l text-red-700 font-bold">
                                    <div className='flex items-center uppercase text-sm tracking-wide text-red-500'>
                                        <ExclamationCircleIcon className="text-red-700 w-5 h-5 mr-2" /> This is an active lawsuit
                                    </div>
                                </div>
                            </div>
                        </main>
                    </div>
                }
            </div> */}

            {/*Cover */}
            <div className='cover'>
                <div className='backdrop-blur-sm bg-white/40'>
                    <div className='max-w-5xl mx-auto px-2 pb-2 md:px-5 md:pb-5'>
                        {/* <div className="pt-2 flex items-center justify-center">
                        <div className="text-gray-600">
                            <nav aria-label="Breadcrumb">
                                <ol role="list" className='flex items-center space-x-2'>
                                    <li className="text-sm">
                                        <a href={`/`} aria-current="page" className="flex items-center font-medium  hover:text-gray-800 capitalize">
                                            <HomeIcon className='h-4 w-4 mr-2' /> Home
                                        </a>
                                    </li>
                                    <svg
                                        width={16}
                                        height={20}
                                        viewBox="0 0 16 20"
                                        fill="currentColor"
                                        xmlns="http://www.w3.org/2000/svg"
                                        aria-hidden="true"
                                        className="w-4 h-5 text-gray-300"
                                    >
                                        <path d="M5.697 4.34L8.98 16.532h1.327L7.025 4.341H5.697z" />
                                    </svg>
                                    <li className="text-sm">
                                        <a href={`/`} aria-current="page" className="flex items-center font-medium  hover:text-gray-800 capitalize">
                                            <ScaleIcon className='h-4 w-4 mr-2' /> Lawsuits
                                        </a>
                                    </li>
                                    <svg
                                        width={16}
                                        height={20}
                                        viewBox="0 0 16 20"
                                        fill="currentColor"
                                        xmlns="http://www.w3.org/2000/svg"
                                        aria-hidden="true"
                                        className="w-4 h-5 text-gray-300"
                                    >
                                        <path d="M5.697 4.34L8.98 16.532h1.327L7.025 4.341H5.697z" />
                                    </svg>
                                    <li className="text-sm">
                                        <a href={`/lawsuits/${lawsuit.slug}`} aria-current="page" className="font-medium  hover:text-gray-800 capitalize">
                                            {lawsuit.title}
                                        </a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div> */}

                        <div className='py-4 lg:py-10 text-gray-700 lg:flex justify-between items-start'>
                            <div>
                                <h1 className="mt-5 text-center lg:text-left text-4xl lg:text-5xl tracking-tight font-black text-gray-800">
                                    {lawsuit.title} Lawsuit
                                    {/* on hover show animation stating this is an active lawsuit... */}
                                </h1>
                                <h2 className="text-center lg:text-left mb-5 md:mb-0 -mt-5 text-base font-semibold text-gray-800 sm:text-lg md:text-2xl lg:mx-0">
                                    {lawsuit.subtitle}
                                </h2>
                            </div>

                            <div onClick={() => { setScrollHide(true); setOpen(!open) }}
                                id="topCtaLawsuitPostButton"
                                className='group w-full my-5 lg:mb-0 lg:w-fit bg-green-600 text-lg md:text-xl hover:bg-green-800 cursor-pointer text-white font-bold rounded-sm uppercase flex items-center justify-center px-6 py-4'>
                                Secure Your Spot <ArrowRightIcon className='transition transform  ease-out group-hover:ease-in motion-reduce:transition-none motion-reduce:hover:transform-none group-hover:translate-x-2 ml-2 h-5 w-5' />
                            </div>
                        </div>

                        <div className='mb-5 md:mb-10 text-gray-700 backdrop-blur-lg bg-white/90 rounded-lg p-4 lg:p-10 shadow-2xl'>
                            <div className='mb-5 font-serif'>
                                {parse(lawsuit.overview)}
                            </div>
                            <div className='flex flex-col md:flex-row items-center justify-between md:mt-10'>
                                <div>
                                    <a onClick={() => { setScrollHide(true); setOpen(!open) }} id="bottomCtaLawsuitPostButton" className="text-center md:text-left group cursor-pointer w-full md:w-fit rounded-md text-gray-700 hover:text-blue-600">
                                        <span className='text-blue-600 underline hover:text-blue-800 text-xl pt-4 lg:pt-0 font-bold flex flex-wrap items-center justify-center md:justify-start'>Learn if you qualify <ArrowRightIcon className='transition transform  ease-out group-hover:ease-in motion-reduce:transition-none motion-reduce:hover:transform-none group-hover:translate-x-2 ml-1 h-4 w-4' /></span>
                                        <p className='mb-0 text-sm text-gray-800 font-normal uppercase tracking-wide italic'>No lawyer fees unless you are awarded compensation</p>
                                    </a>
                                </div>
                                {lawsuit.active_lawsuit && <div className='mb-10 md:mb-0 flex items-center justify-center md:justify-start'>
                                    <ExclamationCircleIcon className='text-red-600 h-5 w-5' />
                                    <p className='mb-0 ml-2 uppercase text-xs tracking-wide font-bold text-gray-400'>Active Lawsuit</p>
                                </div>}
                            </div>

                        </div>
                    </div>
                </div>
            </div>



            {/* Main content */}
            <div className="mt-10 max-w-5xl mx-auto p-2 md:p-7 md:grid md:grid-cols-12 md:gap-10">
                <div className="col-span-12 md:col-span-7">
                    {/* Each section */}
                    <div className='grid grid-cols-12'>
                        {/* Navbar */}
                        <div className='col-span-12 max-w-2xl'>
                            <div className='border-b-2 pb-10 border-gray-200 flex md:hidden mb-20 flex-col text-base text-blue-600 font-semibold'>
                                <p className='mt-10 mb-2 text-gray-600 text-xs'>On this page</p>
                                <a id="InjuriesMobileTableOfContents" href="#injuries-and-side-effects" className='mb-2 hover:text-blue-900'>Injuries and Side Effects</a>
                                <a id="SettlementsMobileTableOfContents" href="#settlements" className='mb-2 hover:text-blue-900'>Settlements</a>
                                <a id="PastLawsuitsMobileTableOfContents" href="#past-lawsuits" className='mb-2 hover:text-blue-900'>Past Lawsuits</a>
                                <a id="CurrentLawsuitsMobileTableOfContents" href="#current-lawsuits" className='mb-2 hover:text-blue-900'>Current Lawsuits</a>
                                <a id="EligibilityMobileTableOfContents" href="#eligibility" className='mb-2 hover:text-blue-900'>Eligibility</a>
                                <a id="ScientificStudiesMobileTableOfContents" href="#scientific-studies" className='mb-2 hover:text-blue-900'>Scientific Studies</a>
                                {/* <a id="TableOfContentsPatientStoriesMobile" href="#patient-stories" className='mb-2 hover:text-blue-900'>Patient Stories</a> */}
                                <a id="FAQMobileTableOfContents" href="#faqs" className='mb-2 hover:text-blue-900'>Frequently Asked Questions</a>
                            </div>
                            {/* injuries and side effects section */}
                            <div id="injuries-and-side-effects">
                                <h3 className="mt-0 pt-10 mb-5 font-semibold text-gray-800 font-serif text-xl md:text-2xl lg:mx-0">
                                    {lawsuit.title} Injuries and Side Effects
                                </h3>
                                <div className='text-gray-700'>{parse(lawsuit.injuries_sideeffects)}</div>

                            </div>

                            {/* complimentary evaluation */}
                            <div className='my-10'>
                                <p className='text-xl text-gray-800'>Get started TODAY and find the right lawyer for your case!</p>
                                <a href="#apply-for-case-review" id="AfterTableOfContentLawsuitPostButton" className='tracking-wide text-xl group my-5 md:my-0 w-full md:w-fit text-white hover:bg-sky-800 bg-sky-600 cursor-pointer font-bold rounded-sm uppercase flex items-center justify-center px-6 py-4'>
                                    Secure your spot <ArrowRightIcon className='transition transform  ease-out group-hover:ease-in motion-reduce:transition-none motion-reduce:hover:transform-none group-hover:translate-x-2 ml-2 h-5 w-5' />
                                </a>
                            </div>


                            {/* Settlement Amounts */}
                            <div id="settlements" className='my-10'>
                                <h3 className="mt-10 pt-10 mb-5 font-semibold text-gray-800 font-serif text-xl md:text-2xl lg:mx-0">
                                    {lawsuit.title} Case Information &amp; Settlements
                                </h3>
                                <div className='text-gray-700'>{parse(lawsuit.caseinfo_settlement)}</div>
                            </div>

                            {/* Fact 1 */}
                            <div className="my-10 px-4 pt-8 pb-4 flex font-semibold text-sm border-l-8 border-yellow-600 text-gray-700 bg-yellow-100 shadow-sm">
                                <div className="p-2 h-12 w-12 flex items-center mr-2">
                                    <InformationCircleIcon className="text-yellow-500 h-12 w-12" />
                                </div>
                                <div className="mt-4 italic text-xl">{parse(lawsuit.fact_1)}  <Link href={`/case-review/${lawsuit.slug}`}><a className="-mt-4 mb-4 flex items-center">Learn more <ArrowRightIcon className="ml-2 h-4 w-4" /></a></Link></div>

                            </div>


                            {
                                open &&
                                <div>
                                    <StepModal
                                        source={source}
                                        open={open}
                                        setOpen={setOpen}
                                        title="Contact Us"
                                        product={lawsuit.title}
                                        lawsuit={`${lawsuit.title} Lawsuit`}
                                        injuries={lawsuit.injuries}
                                        setSuccess={setSuccess} />
                                </div>
                            }

                            {/* lawsuit information */}
                            <div id="past-lawsuits" className='my-10'>
                                <h3 className="mt-10 pt-10 mb-5 font-semibold text-gray-800 font-serif text-xl md:text-2xl lg:mx-0">
                                    Past {lawsuit.title} Lawsuits
                                </h3>
                                <div className='text-gray-700'>{parse(lawsuit.past_lawsuits)}</div>
                            </div>

                            <div className='relative p-5 bg-gray-100 rounded-lg'>
                                <div className='pt-10 bg-white'>
                                    <div className='p-5'>
                                        <h3 className='mt-0 text-gray-900 font-semibold text-xl mb-5 capitalize'>Secure your settlement - Speak with a {lawsuit.title} lawyer</h3>
                                        <p className='text-gray-600'>Have you or a loved one been diagnosed with <span className='font-bold'>{lawsuit.injuries[0]}</span> after using {lawsuit.title}?</p>
                                        <p className='text-gray-700 lg:w-44 xl:w-fit font-semibold italic'>Take the time to see if you qualify.</p>

                                        <div className='mt-10'>
                                            <a onClick={() => setOpen(!open)} id="RedCaseReviewModalThreeLwyrImageLawsuitPostButton" className="z-10 relative cursor-pointer w-full md:w-fit text-xl font-semibold px-6 py-4 bg-red-500 hover:bg-red-800 text-white rounded-sm">
                                                Free Consultation
                                            </a>
                                        </div>
                                    </div>
                                    <div className='relative z-0 ml-auto -mt-10 sm:-mt-52 md:-mt-24 lg:-mt-52 w-72'>
                                        <Image src="/2.png" alt="Lawyers"
                                            width={200}
                                            height={200}
                                            objectFit="contain"
                                            layout="responsive" />
                                    </div>
                                </div>
                            </div>

                            {/* lawsuit information */}
                            <div id="current-lawsuits" className='my-10'>
                                <h3 className="mt-10 pt-10 mb-5 font-semibold text-gray-800 font-serif text-xl md:text-2xl lg:mx-0">
                                    Current {lawsuit.title} Lawsuits
                                </h3>
                                <div className='text-gray-700'>{parse(lawsuit.current_lawsuits)}</div>
                            </div>

                            {/* Fact 2 */}
                            <div className="my-10 px-4 pt-8 pb-4 flex font-semibold text-sm border-l-8 border-yellow-600 text-gray-700 bg-yellow-100 shadow-sm">
                                <div className="p-2 h-12 w-12 flex items-center mr-2">
                                    <InformationCircleIcon className="text-yellow-500 h-12 w-12" />
                                </div>
                                <div className="mt-4 italic text-xl">{parse(lawsuit.fact_2)}  <Link href={`/case-review/${lawsuit.slug}`}><a className="-mt-4 mb-4 flex items-center">Learn more <ArrowRightIcon className="ml-2 h-4 w-4" /></a></Link></div>
                            </div>



                            {/* who is eligible */}
                            <div id="eligibility" className='my-10'>
                                <h3 className="mt-10 pt-10 mb-5 font-semibold text-gray-800 font-serif text-xl md:text-2xl lg:mx-0">
                                    Eligibility for {lawsuit.title} lawsuit
                                </h3>
                                <div className='text-gray-700'>{parse(lawsuit.eligibility)}</div>
                            </div>



                            {/* complimentary evaluation */}
                            <div className='my-10'>
                                <a href="#apply-for-case-review" id="AfterCurrentLawsuitLawsuitPostButton" className='tracking-wide text-xl group my-5 md:my-0 w-full md:w-fit text-white hover:bg-sky-800 bg-sky-600 cursor-pointer font-bold rounded-sm flex items-center justify-center px-6 py-4'>
                                    Find A {lawsuit.title} Lawyer <ArrowRightIcon className='transition transform  ease-out group-hover:ease-in motion-reduce:transition-none motion-reduce:hover:transform-none group-hover:translate-x-2 ml-2 h-5 w-5' />
                                </a>
                            </div>


                            {/* FDA and Scientific Studies */}
                            <div id="scientific-studies" className='my-10'>
                                <h3 className="mt-10 pt-10 mb-5 font-semibold text-gray-800 font-serif text-xl md:text-2xl lg:mx-0">
                                    FDA &amp; Scientific Studies on {lawsuit.title}
                                </h3>
                                <div className='text-gray-700'>{parse(lawsuit.scientific_studies)}</div>
                            </div>

                            {/* Fact 3 */}
                            <div className="my-10 px-4 pt-8 pb-4 flex font-semibold text-sm border-l-8 border-yellow-600 text-gray-700 bg-yellow-100 shadow-sm">
                                <div className="p-2 h-12 w-12 flex items-center mr-2">
                                    <InformationCircleIcon className="text-yellow-500 h-12 w-12" />
                                </div>
                                <div className="mt-4 italic text-xl">{parse(lawsuit.fact_3)}  <Link href={`/case-review/${lawsuit.slug}`}><a className="-mt-4 mb-4 flex items-center">Learn more <ArrowRightIcon className="ml-2 h-4 w-4" /></a></Link></div>
                            </div>

                            {/* Questions by lawyers */}
                            <div id="lawyer-questions" className='my-10'>
                                <h3 className="mt-10 pt-10 mb-5 font-semibold text-gray-800 font-serif text-xl md:text-2xl lg:mx-0">
                                    Questions asked by {lawsuit.title} Lawyers
                                </h3>
                                <div className='text-gray-700'>{parse(lawsuit.lawyer_questions)}</div>
                            </div>


                            <div className='my-10 relative flex justify-center items-center h-60 md:h-80 rounded-sm shadow-lg drop-shadow-lg w-full bg-gray-200'>
                                <Image src={lawsuit.product_cover_image}
                                    className="w-auto h-auto"
                                    layout='fill'
                                    objectFit="cover"
                                    objectPosition="center"
                                />
                            </div>

                            {/* complimentary evaluation */}
                            <div className='my-10'>
                            <p className='text-xl text-gray-800'>Get the professional help you need in this difficult time!</p>
                                <a href="#apply-for-case-review" id="AfterFAQsLawsuitPostButton" className='group my-5 md:my-0 w-full md:w-fit text-white hover:bg-red-800 bg-red-600 text-base cursor-pointer font-bold rounded-sm uppercase flex items-center justify-center px-6 py-4'>
                                    Get your free case review <ArrowRightIcon className='transition transform  ease-out group-hover:ease-in motion-reduce:transition-none motion-reduce:hover:transform-none group-hover:translate-x-2 ml-2 h-5 w-5' />
                                </a>
                            </div>



                            {/* FAQs Accordion*/}
                            <div id="faqs" className='my-10'>
                                <h3 className="mt-10 pt-10 mb-5 font-semibold text-gray-800 font-serif text-xl md:text-2xl lg:mx-0">
                                    {lawsuit.title} Lawsuit FAQs
                                </h3>
                                <div className='text-gray-700'>
                                    {accordionArray.map((item) => (
                                        <div key={item.id}>
                                            <AccordionItem item={item} title={lawsuit.title} href="#apply-for-case-review" />
                                        </div>
                                    ))}
                                </div>
                            </div>


                            {/* How to apply */}
                            {/* <div id="scientific-studies" className='my-10'>
                                <p className="mt-10 pt-10 mb-5 font-semibold text-gray-800 font-serif text-xl md:text-2xl lg:mx-0">
                                    How long does it take to settle on a {lawsuit.title} Case?
                                </p>
                                <p className='text-gray-700'>{parse(lawsuit.timeline)}</p>
                            </div> */}


                            {/* Get started */}
                            <div id="apply-for-case-review" className='my-10'>
                                <h3 className="mt-10 pt-10 mb-5 font-semibold text-gray-800 font-serif text-xl md:text-2xl lg:mx-0">
                                    How do I get my case reviewed by a {lawsuit.title} lawyer?
                                </h3>
                                <h4 className='mt-0 text-gray-700 uppercase tracking-widest font-semibold mb-10'>Start with a complimentary case evaluation</h4>
                                <p className='text-gray-500 mb-10'>Case reviews are provided for complimentary unless you are awarded compensation for your {lawsuit.title} Lawsuit. </p>
                                <p className='italic text-gray-500 mb-10'>Please answer the following preliminary questions so we can better connect you with a qualified law firm.</p>
                                <div className='relative p-5 md:p-10 bg-sky-600 rounded-lg'>
                                    <div className='bg-white'>
                                        <div onClick={() => setScrollHide(true)}>
                                            <StepFormV2
                                                source={source}
                                                title={`Diagnosed with cancer after ${lawsuit.title} use?`}
                                                lawsuit={`${lawsuit.title} Lawsuit`}
                                                product={`${lawsuit.title}`}
                                                injuries={lawsuit.injuries}
                                                setSuccess={setSuccess}
                                            />
                                        </div>
                                    </div>
                                </div>


                            </div>

                            {/* Works cited */}
                            <div id="works-cited" onClick={() => showCited(!cited)} className='my-10 group'>
                                <h3 className="mt-10 pt-10 mb-10 font-semibold text-gray-800 font-serif text-xl md:text-2xl lg:mx-0">
                                    {lawsuit.cited_articles.length} Cited Articles
                                </h3>
                                {cited
                                    ?
                                    <ul className='text-gray-500 list-decimal ml-5'>
                                        {lawsuit.cited_articles.map((item, i) =>
                                            <li className="text-sm mb-2 break-all" key={i}>{item}</li>
                                        )}
                                    </ul>
                                    :
                                    <>
                                        <div style={{ position: "relative", zIndex: "10" }}>
                                            <ul className='text-gray-500 list-decimal ml-5'>
                                                <li className="text-sm mb-2 break-all">{lawsuit.cited_articles[0]}</li>
                                                <li className="text-sm mb-2 break-all">{lawsuit.cited_articles[1]}</li>
                                                <li className="text-sm mb-2 break-all">{lawsuit.cited_articles[2]}</li>
                                            </ul>
                                        </div>
                                        <div className='bg-gradient-to-t -mt-20 from-zinc-50 h-20' style={{ position: "relative", zIndex: "50" }}></div>
                                    </>
                                }
                                <div className='group-hover:text-gray-900 text-gray-400 flex items-center justify-center'>
                                    {cited ? <ChevronUpIcon className='h-8 w-8' /> : <ChevronDownIcon className='h-8 w-8' />}
                                </div>
                            </div>

                            <div className='mt-10 mb-20 text-center border-t-2 border-gray-200'>
                                <h3 className="pt-10 my-8 font-semibold text-gray-600 uppercase text-sm tracking-wider  lg:mx-0">
                                    Share this page
                                </h3>
                                <div className='flex items-center justify-center text-gray-400'>
                                    {/* Facebook Share*/}
                                    <Link href={`https://www.facebook.com/sharer/sharer.php?u=https://thelegalcanary.com/lawsuit/${lawsuit.slug}`} className="fb-share-button" data-href={`https://thelegalcanary.com/lawsuit/${lawsuit.slug}`} passHref>
                                        <a id="socialFacebookButton" className='text-blue-600 hover:text-blue-900' aria-label="Share as Facebook Post">
                                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                                className='w-10 mr-10'
                                                viewBox="0 0 50 50" >
                                                <path fill="currentColor"
                                                    d="M25,3C12.85,3,3,12.85,3,25c0,11.03,8.125,20.137,18.712,21.728V30.831h-5.443v-5.783h5.443v-3.848 c0-6.371,3.104-9.168,8.399-9.168c2.536,0,3.877,0.188,4.512,0.274v5.048h-3.612c-2.248,0-3.033,2.131-3.033,4.533v3.161h6.588 l-0.894,5.783h-5.694v15.944C38.716,45.318,47,36.137,47,25C47,12.85,37.15,3,25,3z">
                                                </path>
                                            </svg>
                                        </a>
                                    </Link>
                                    {/* Twitter Share */}
                                    <Link href={`https://twitter.com/intent/tweet?url=https://thelegalcanary.com/lawsuit/${lawsuit.slug}`} passHref>
                                        <a id="socialTwitterButton" className='text-sky-400 hover:text-sky-600' aria-label="Share as a Tweet">
                                            <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="Twitter"
                                                className="w-10 mr-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                <path fill="currentColor"
                                                    d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z">
                                                </path>
                                            </svg>
                                        </a>
                                    </Link>
                                    {/* Mail icon share */}
                                    <Link href={`mailto:?&subject=&body=https://thelegalcanary.com/lawsuit/${lawsuit.slug}`} passHref>
                                        <a id="socialEmailButton" className='text-gray-400 hover:text-gray-600' aria-label="Share as an Email">
                                            <MailIcon className='h-12 w-12' />
                                        </a>
                                    </Link>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>

                <div className="col-span-12 md:col-span-5 md:my-0 my-10 md:ml-10">
                    <div className='hidden md:flex mb-10 flex-col text-base text-sky-600 font-semibold'>
                        <p className='mt-10 mb-2 text-gray-600 text-sm'>On this page</p>
                        <a id="TableOfContentsInjuriesLargeDevices" href="#injuries-and-side-effects" className='mb-2 hover:text-blue-900'>Injuries and Side Effects</a>
                        <a id="TableOfContentsSettlementsLargeDevices" href="#settlements" className='mb-2 hover:text-blue-900'>Settlements</a>
                        <a id="TableOfContentsPastLawsuitsLargeDevices" href="#past-lawsuits" className='mb-2 hover:text-blue-900'>Past Lawsuits</a>
                        <a id="TableOfContentsCurrentLawsuitsLargeDevices" href="#current-lawsuits" className='mb-2 hover:text-blue-900'>Current Lawsuits</a>
                        <a id="TableOfContentsEligibilityLargeDevices" href="#eligibility" className='mb-2 hover:text-blue-900'>Eligibility</a>
                        <a id="TableOfContentsScientificStudiesLargeDevices" href="#scientific-studies" className='mb-2 hover:text-blue-900'>Scientific Studies</a>
                        {/* <a id="TableOfContentsPatientStoriesLargeDevices" href="#patient-stories" className='mb-2 hover:text-blue-900'>Patient Stories</a> */}
                        <a id="TableOfContentsFAQsLargeDevices" href="#faqs" className='mb-2 hover:text-blue-900'>Frequently Asked Questions</a>
                        {/* <a href="#apply-for-case-review" id="topCtaButton" className='my-5 md:my-8 w-full md:w-fit bg-green-200  hover:bg-sky-600 cursor-pointer text-green-800 hover:text-white font-bold rounded-full uppercase flex items-center justify-center px-4 py-2'>
                            Get Started <ArrowRightIcon className='ml-2 h-5 w-5' />
                        </a> */}
                    </div>
                    {/* quickview - lawsuit card information */}
                    <div className="bg-white shadow rounded-lg">
                        <div className='md:rounded-t-md relative flex justify-center rounded-t-lg items-center h-40 min-h-40 w-full bg-gray-200'>
                            {lawsuit.product_image &&
                                <Image
                                    src={lawsuit.product_image}
                                    alt={lawsuit.title}
                                    layout="fill"
                                    objectFit="cover"
                                    className="md:rounded-t-md z-0 drop-shadow w-full h-full object-center object-cover"
                                />
                            }
                        </div>
                        <div className='px-4 pb-1' >
                            {lawsuit.product_image_source &&
                                <p className="italic text-gray-500 text-xs mt-2 mb-10 text-center">
                                    (Image Source: {lawsuit.product_image_source})
                                </p>
                            }
                            <p className='mt-8 text-sm font-bold text-gray-700 mb-2 uppercase tracking-widest'>Issues</p>
                            <p className='text-gray-600 text-lg'>{lawsuit.products}</p>
                            {lawsuit.carcinogen_related &&
                                <>
                                    <p className='mt-2 text-sm font-bold text-gray-700 mb-2 uppercase tracking-widest flex items-center' onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>Carcinogen <QuestionMarkCircleIcon className='h-4 w-4' /></p>
                                    {isHovering && <div className="bg-black text-xs italic rounded-lg p-2 text-white absolute">substance, organism or agent capable of causing cancer</div>}
                                    <p className='text-gray-600 text-lg'>{lawsuit.carcinogen}</p>
                                </>
                            }
                            <p className='mt-2 text-sm font-bold text-gray-700 mb-2 uppercase tracking-widest'>Related Injuries</p>
                            <p className='text-gray-600 text-lg'>
                                {lawsuit.injuries.join("; ")}
                            </p>
                            <p className='mt-2 text-sm font-bold text-gray-700 mb-2 uppercase tracking-widest'>Top Settlement/Jury Verdict</p>
                            <p className='text-gray-600 text-lg'>{lawsuit.top_settlement}</p>
                            <p className='mt-2 text-sm font-bold text-gray-700 mb-2 uppercase tracking-widest'>Defendants</p>
                            <p className='text-gray-600 text-lg'>{lawsuit.defendants.join("; ")}</p>
                        </div>
                    </div>
                    {/* complimentary evaluation form on the far right side after the fact info Clipboard, follows on scroll.  */}
                    <div className="mt-10" onClick={() => setScrollHide(true)}>
                        <StepForm
                            source={source}
                            bgColor="bg-red-50"
                            mainBgColor="bg-red-500"
                            title={`THINK YOU MAY QUALIFY?`}
                            lawsuit={`${lawsuit.title} Lawsuit`}
                            product={`${lawsuit.title}`}
                            injuries={lawsuit.injuries}
                            setSuccess={setSuccess}
                        />
                    </div>
                </div>
            </div>
            <style>
                {
                    `.cover {
                background-image: linear-gradient(to top, rgb(255, 255, 255, 0), rgb(255, 255, 255, 0.9)), url(${lawsuit.product_cover_image}); /* The image used */
                background-color: #cccccc; /* Used if the image is unavailable */
                height: auto;
                /* Create the parallax scrolling effect */
                background-attachment: fixed;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                z-index: auto;
            }`
                }
            </style>
        </div>

    )
}